import React from 'react';

import styles from './particles.module.scss';
import particlesData from './particles.config.json';

require('particles.js');
declare global {
  interface Window {
    particlesJS: any;
  }
}
const particlesJS = window.particlesJS;


class Particles extends React.Component {
  render() {
    return (
      <div id="particles-js" className={styles.wrapper}></div>
    );
  }

  componentDidMount() {
    particlesJS("particles-js", particlesData);
  }
}

export default Particles;
