import React from 'react';
import logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import styles from './app.module.scss';
import clsx from 'clsx';
import Particles from './particles';

let CONTACT_EMAIL = "kontakt@" + window.location.hostname;

class App extends React.Component {
  render() {
    return (
      <div className={styles.appContainer}>
        <div className={styles.appBackground}>
          <Particles />
        </div>
        <div className={clsx(styles.appContent, styles.mousePassThrough)}>
          <div className={clsx("px-4", "text-center", "container", styles.heroContainer)}>
            <div className="col-lg-6 mx-auto">
              <img src={logo} className={clsx(styles.logo, "img-fluid")} alt="logo" />
              <p className={clsx("lead mb-3", styles.lead, styles.mousePassThroughDisabled)}>We make software, not compromises.</p>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mt-5">
                <a href="tel:+48-883-930-520" className={styles.mousePassThroughDisabled}>
                  <button type="button" className={clsx("btn btn-primary btn-lg", styles.btnPhone, "me-sm-1")}>
                    <span className={clsx("me-3", styles.btnPhoneIcon)}><FontAwesomeIcon icon={faPhoneAlt} /></span>
                    <span className={clsx(styles.textWarning, "me-1")}>+48</span> 883 930 520
                  </button>
                </a>
                <a href={"mailto:" + CONTACT_EMAIL} className={styles.mousePassThroughDisabled}>
                  <button type="button" className={clsx("btn btn-outline-secondary btn-lg", styles.btnMail)}>
                  <span className={clsx("me-3", styles.btnMailIcon)}><FontAwesomeIcon icon={faEnvelope} /></span>
                  <span>{CONTACT_EMAIL}</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
